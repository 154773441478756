
import { defineComponent, onMounted, ref } from "vue";
import { Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Auth } from "aws-amplify";
import * as Yup from "yup";
import router from "@/router";
import store from "@/store";
import { PASSWORD } from "@/helper/globalConst";
import { Actions } from "@/store/enums/StoreEnums";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ElNotification } from "element-plus";

export default defineComponent({
  name: "changepassword",
  components: {
    Field,
    Form,
  },
  data() {
    return {
      organizationRoles: "",
      customerRoles: "",
      currentpassword: "",
      newpassword: "",
      confirmpassword: "",
      flag: true,
      show:false,
    };
  },
  methods: {
    showPassword(){
      this.show=!this.show
    },
    async changepassword() {
      let user = store.getters.currentUser;

      let cognitoUser = Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(
            user,
            this.currentpassword,
            this.newpassword
          );
        })

        .then(() => {
          ElNotification({
            title: 'Success',
            message: 'Password Updated!',
            type: 'success',
            duration:2000,
            position: 'top-right'
          })
          store
              .dispatch(Actions.LOGOUT)
              .then(() => router.push({ name: "sign-in" }));  
         
        })
        .catch((error) =>
         ElNotification({
            title: 'Error',
            message: 'Incorrect Current Password',
            type: 'error',
            duration:2000,
            position: 'top-right'
          })
        );
    },
  },
  setup() {
    const changePasswordButton = ref<HTMLElement | null>(null);
    const change = Yup.object().shape({
      currentpassword: Yup.string().required().label("Current password"),
      newpassword: Yup.string()
        .notOneOf([Yup.ref("currentpassword"), null], "Password must be different from the old password")
        .min(8, "Password should have minimum length 8")
        .matches(
          PASSWORD,
          "Password should have minimum length 8 including atleast one Uppercase, One Lowercase, One Number [0-9] and atleast one special case Character @ $ % & ! *?#"
        )
        .required()
        .label("Password"),
      confirmpassword: Yup.string()
        .min(8)
        .required("Field is required")
        .oneOf([Yup.ref("newpassword"), null], "Password must match")
        .label("Password Confirmation"),
    });

    onMounted(() => {
      let user = store.getters.currentUser;
       if(Object.keys(user).length==0)
      {
     router.push({ path: "/sign-in" });
      }
      setCurrentPageBreadcrumbs("Change Password", ["Account"]);
    });

    return {
      change,
      changePasswordButton,
    };
  },
});
